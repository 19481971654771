/**
 * Partners slider before footer website
 * https://splidejs.com/tutorials/gallery/
 */

const splide = new Splide("#partners-slider", {
  perMove: 1,
  perPage: 1,
  autoHeight: true, // Not working ?
  pagination: false,
  arrows: false
});

function initThumbnail(thumbnail, index) {
  thumbnail.addEventListener("click", function () {
    splide.go(index);
  });
}

const thumbnails = document.getElementsByClassName("partners__nav__item");
let current;

for (var i = 0; i < thumbnails.length; i++) {
  initThumbnail(thumbnails[i], i);
}


splide.on("mounted move", function () {
  const thumbnail = thumbnails[splide.index];

  if (thumbnail) {
    if (current) {
      current.classList.remove("is-active");
    }

    thumbnail.classList.add("is-active");
    current = thumbnail;
  }
});

splide.mount();
