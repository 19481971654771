/* ==========================================================================
  main.js
========================================================================== */

'use strict';
/* global fitvids, cssVars */

/**
 * Init responsive videos
 */
fitvids();

/**
 * Init CSS vars ponyfill
 */
cssVars({});

const homeSlider = document.querySelector('.home-slider');

/**
 * Initialize slider for home page
 */
if ( homeSlider ) {

  document.addEventListener( 'DOMContentLoaded', function () {

    new Splide( '.home-slider', {
			type : 'slide',
      width : '100vw',
			autoplay: 'pause',
      perPage : 1,
      perMove : 1,
			pagination: true,
      breakpoints: {
        800: {
          arrows: false
        }
      }
    }).mount();

  });

}
